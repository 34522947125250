<template>
  <div>
    <base-header class="pb-4 pb-4 pt-5 pt-md-5"> </base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Importar Contatos</h3>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <!-- porto -->

                <div class="row">
                  <div class="col-lg-12" v-if="response.success">
                    <div class="alert alert-success">
                      <i class="fa fa-check"></i>
                      <span v-html="response.success"></span>
                    </div>
                  </div>
                  <div class="col-lg-12" v-if="response.errors.length">
                    <div class="alert alert-danger">
                      <div v-for="erro in response.errors">
                        <i class="fa fa-exclamation-circle"></i>
                        <span>{{ erro }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <label class="form-control-label w-100"
                      >Adiciona a planilha *</label
                    >
                    <input-file
                      alternative=""
                      label="Porto"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      :errors="errors.arquivo"
                      required
                      class="form-control form-control-alternative"
                      ref="arquivo"
                      v-model="model.arquivo"
                    />
                  </div>
                </div>
                <!-- /porto -->
              </form>
            </template>

            <div class="mt-4">
              <button
                type="submit"
                class="btn base-button btn-dark float-left"
                @click="$router.push('/contatos/listar')"
              >
                See listing
              </button>
              <button
                type="submit"
                class="btn base-button btn-primary float-right"
                :disabled="loading"
                @click="submit()"
              >
                Importar contatos
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Service from "@/services/Contatos";
  export default {
    name: "contatos_import",

    created() {},
    data() {
      return {
        response: {
          success: null,
          errors: [],
        },
        errors: {},
        loading: false,
        Service,
        model: {
          arquivo: null,
        },
      };
    },

    methods: {
      submit() {
        this.loading = true;
        this.response.errors = [];
        this.errors = {};
        this.response.success = null;
        this.$notify({
          type: "info",
          message: "Por favor aguarde o término da importação",
          horizontalAlign: "center",
        });
        Service.importar(this.model)
          .then((response) => {
            this.loading = false;
            this.response = response.data;
            this.model.arquivo = null;
            this.$refs.arquivo.$refs.inputFile.type = "text";
            this.$refs.arquivo.$refs.inputFile.type = "file";
          })
          .catch((err) => {
            this.loading = false;
            if (err.response && err.response.data && err.response.data.errors) {
              let errors = err.response.data.errors;
              this.errors = errors;
              this.$notify({
                type: "danger",
                message: "Please check the form",
                horizontalAlign: "center",
              });
            }
          });
      },
    },
  };
</script>
<style></style>
