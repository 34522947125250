<template>
  <div>
    <base-header class="pb-4 pb-4 pt-5 pt-md-5"> </base-header>
    <div class="container-fluid" style="padding: 0 !important">
      <!-- pesquisa -->
      <div class="card" body-classes="px-0 pb-1" footer-classes="pb-2">
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col-lg-2">
              <h3 class="mb-0">
                List <i class="fa fa-spinner fa-spin" v-if="loading"></i>
              </h3>
            </div>

            <div class="col text-right">
              <base-button type="dark" icon size="sm" @click="downloadRecap()">
                <i class="fas fa-file-excel mr-2"></i>
                <span class="btn-inner--text">Download RECAP</span>
              </base-button>
              <base-button type="dark" icon size="sm" @click="download(true)">
                <i class="fas fa-file-excel mr-2"></i>
                <span class="btn-inner--text">Download lineups</span>
              </base-button>

              <base-button type="dark" icon size="sm" @click="download()">
                <i class="fas fa-file-excel mr-2"></i>
                <span class="btn-inner--text"
                  >Download lineups (without agencies)</span
                >
              </base-button>
              <base-button
                type="primary"
                icon
                size="sm"
                @click="$router.push('/lineups/importar')"
              >
                <i class="fas fa-plus mr-2"></i>
                <span class="btn-inner--text">Import lineups</span>
              </base-button>
              <base-button type="info" icon size="sm" @click="cleanFiltros()">
                <i class="fas fa-filter mr-2"></i>
                <span class="btn-inner--text">Clear filters</span>
              </base-button>
              <base-button
                type="primary"
                icon
                size="sm"
                @click="openCreateForm({ id: null })"
              >
                <i class="fas fa-plus mr-2"></i>
                <span class="btn-inner--text">New vessel</span>
              </base-button>
            </div>
          </div>

          <div class="row align-items-center mt-3">
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Ports"
                placeholder="Select the ports"
                :options="portosFiltro"
                optionsValue="name"
                v-model="search.porto_id"
              />
            </div>

            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Cargos"
                placeholder="Select the cargos"
                :options="cargas"
                optionsValue="name"
                :disabled="cargaSelecionada.id ? true : false"
                v-model="search.carga_tipo_id"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Agency"
                placeholder="Select the agency"
                :options="agencias"
                optionsValue="name"
                v-model="search.agencia_id"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Vessels"
                placeholder="Select the vessels"
                :options="navios"
                optionsValue="name"
                v-model="search.navio_id"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Charteres"
                placeholder="Select the charteres"
                :options="afretadores"
                optionsValue="name"
                v-model="search.afretador_id"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Last port"
                placeholder="select the last port"
                :options="lineupPortos"
                optionsValue="port_name"
                v-model="search.import_porto"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Next port"
                placeholder="select the next port"
                :options="lineupPortos"
                optionsValue="port_name"
                v-model="search.export_porto"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Shippers"
                placeholder="Select the shippers"
                :options="trabalhadores"
                optionsValue="name"
                v-model="search.embarcadores"
              />
            </div>
            <div class="col-lg-3">
              <multiple-select
                multiple
                label="Receivers"
                placeholder="select the receivers"
                :options="trabalhadores"
                optionsValue="name"
                v-model="search.recebedores"
              />
            </div>
            <div class="col-lg-3">
              <base-input
                type="date"
                alternative=""
                label="Start of period"
                input-classes="form-control-alternative"
                v-model.lazy="search.periodo_inicio"
              />
            </div>
            <div class="col-lg-3">
              <base-input
                type="date"
                alternative=""
                label="End of period"
                input-classes="form-control-alternative"
                v-model.lazy="search.periodo_fim"
              />
            </div>

            <div class="col-lg-3">
              <div class="form-group has-label float-right w-100">
                <label class="form-control-label" style="color: transparent">
                  search</label
                >
                <button
                  type="submit"
                  class="btn base-button btn-primary w-100"
                  :disabled="loading"
                  @click="listar()"
                >
                  <i class="fa fa-search"></i> search
                </button>
              </div>
              <!-- <button type="submit" class="btn base-button btn-primary w-100" @click="submit()"><i class="fa fa-check"></i></button> -->
            </div>
          </div>
        </div>
      </div>
      <!-- pesquisa -->
      <div class="card mt-3">
        <div class="tabs">
          <div class="card-header">
            <ul class="nav nav-pills card-header-pills">
              <li class="nav-item" v-for="carga in cargas">
                <a
                  role="tab"
                  target="_self"
                  class="nav-link cursor-pointer"
                  @click="setCarga(carga)"
                  :class="{ active: cargaSelecionada == carga }"
                  >{{ carga.name }}</a
                >
              </li>
              <li class="nav-item">
                <a
                  role="tab"
                  target="_self"
                  class="nav-link cursor-pointer"
                  @click="setCarga({})"
                  :class="{ active: !cargaSelecionada.id }"
                  >ALL CARGOS</a
                >
              </li>
            </ul>
          </div>
          <div class="tab-content">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0 text-center">
                    {{ cargaSelecionada.name }}
                    <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                    <base-dropdown
                      class="dropdown float-right"
                      position="right"
                      v-if="cargaSelecionada.id"
                    >
                      <a
                        slot="title"
                        class="btn btn-sm btn-icon-only text-light"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fas fa-ellipsis-v"></i>
                      </a>

                      <template>
                        <a
                          class="dropdown-item"
                          @click="cargaAtualizada(cargaSelecionada, 1)"
                          ><i class="fas fa-check" style="min-width: 20px"></i>
                          Mark as updated</a
                        >

                        <a
                          class="dropdown-item"
                          @click="cargaAtualizada(cargaSelecionada, 0)"
                          ><i class="fas fa-ban" style="min-width: 20px"></i>
                          Remove updated</a
                        >
                      </template>
                    </base-dropdown>
                  </h3>
                  <h4
                    class="mb-0 text-center"
                    v-if="cargaSelecionada.atualizado_data"
                  >
                    <i class="fa fa-clock"></i>
                    {{ cargaSelecionada.atualizado_data | date }} -
                    {{ cargaSelecionada.atualizador.name }}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card my-3 lineup"
        footer-classes="pb-2"
        v-for="porto in portos"
      >
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 text-center">
                {{ porto.name }}
                <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                <base-dropdown
                  class="dropdown float-right"
                  position="right"
                  v-if="cargaSelecionada.id"
                >
                  <a
                    slot="title"
                    class="btn btn-sm btn-icon-only text-light"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i class="fas fa-ellipsis-v"></i>
                  </a>

                  <template>
                    <a
                      class="dropdown-item"
                      @click="portoAtualizado(porto, cargaSelecionada.id, 1)"
                      ><i class="fas fa-check" style="min-width: 20px"></i> Mark
                      as updated</a
                    >

                    <a
                      class="dropdown-item"
                      @click="portoAtualizado(porto, cargaSelecionada.id, 0)"
                      ><i class="fas fa-ban" style="min-width: 20px"></i> Remove
                      updated</a
                    >
                  </template>
                </base-dropdown>
              </h3>
              <h4
                class="mb-0 text-center"
                v-if="
                  porto.carga_atualizada &&
                  porto.carga_atualizada.atualizado_data
                "
              >
                <i class="fa fa-clock"></i>
                {{ porto.carga_atualizada.atualizado_data | date }} -
                {{ porto.carga_atualizada.atualizador.name }}
              </h4>
            </div>
          </div>
        </div>

        <div
          class="card-header border bordered"
          v-for="terminal in porto.terminais"
        >
          <div class="row align-items-center my-2">
            <div class="col-lg-12">
              <h3 class="mb-0 text-center">
                <div class="bagde badge-dark">
                  {{ terminal.name }}
                  <i class="fa fa-spinner fa-spin" v-if="loading"></i>
                </div>
              </h3>
            </div>
            <div class="col-lg-12 mt-1">
              <base-button
                v-if="!terminal.inEdit"
                class="float-left m-1 p-1"
                @click="toggleEditTerminal(terminal)"
                :type="terminal.inEdit ? 'danger' : 'primary'"
                icon
                size="sm"
              >
                <i class="fas fa-edit mr-2"></i>
                <span class="btn-inner--text" v-if="!terminal.inEdit"
                  >Edit lines</span
                >
                <span class="btn-inner--text" v-else>Cancel edit</span>
              </base-button>

              <base-button
                v-if="!terminal.inEdit"
                class="float-right m-1 p-1"
                type="primary"
                icon
                size="sm"
                @click="
                  openCreateForm({
                    id: null,
                    premodel: {
                      terminal_id: terminal.id,
                      porto_id: terminal.porto_id,
                    },
                  })
                "
              >
                <i class="fas fa-plus mr-2"></i>
                <span class="btn-inner--text">New vessel</span>
              </base-button>
            </div>
          </div>
          <table_lineup
            v-if="!terminal.inEdit"
            @openModal="openCreateForm"
            @reload="listar"
            :carga="cargaSelecionada"
            :lineups="terminal.lineups"
            :terminais="porto.terminais"
          ></table_lineup>
          <table_form
            v-else
            @cancelEdit="toggleEditTerminal(terminal)"
            @openModal="openCreateForm"
            :carga="cargaSelecionada"
            :cargas="cargasByid"
            :lineups="terminal.lineups"
            :terminal="terminal"
            :ops="ops"
          ></table_form>
        </div>
      </div>

      <div
        class="card my-3"
        footer-classes="pb-2"
        v-if="!list.length && !loading"
      >
        <div class="card-header border-0">
          <div class="row align-items-center">
            <div class="col">
              <h3 class="mb-0 text-center">
                No lineup registered<i
                  class="fa fa-spinner fa-spin"
                  v-if="loading"
                ></i>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <excluir-modal
      :show="$root.modal.excluir.isOpen"
      :mensagem="$root.modal.excluir.mensagem"
      :model="$root.modal.excluir.model"
      :service="$root.modal.excluir.service"
      @close="handleExcluir"
    ></excluir-modal>
    <modal
      :show="modal.form.show"
      @close="
        () => {
          modal.form.show = false;
          listar(true);
        }
      "
      modalClasses="modal-xl"
    >
      <template v-slot:header>
        <span style="color: transparent">Lineup</span>
      </template>
      <template>
        <lineup-form
          v-if="modal.form.show"
          :id="modal.form.id"
          :premodel="modal.form.premodel"
          :isModal="1"
          :call-in-id="modal.form.call_in_id"
          :success="handleModalForm"
        />
      </template>
    </modal>
  </div>
</template>

<script>
  import moment from "moment";
  import Multiselect from "vue-multiselect";
  import Service from "@/services/Lineups";
  import PortosService from "@/services/Portos";
  import CargasTiposService from "@/services/CargasTipos";
  import TableLineup from "./Table.vue";
  import TableForm from "./TableForm.vue";
  import FormLineup from "./Form.vue";
  export default {
    components: {
      table_lineup: TableLineup,
      table_form: TableForm,
      "lineup-form": FormLineup,
      Multiselect,
    },
    data() {
      return {
        Service,
        cargaSelecionada: {},
        cargas: [],
        navios: [],
        afretadores: [],
        terminais: [],
        agencias: [],
        bercos: [],
        terminal: {},
        lineupPortos: [],
        trabalhadores: [],
        status: [],
        cargasByid: [],
        loading: false,
        pagination: {
          page: 1,
          last_page: 1,
          per_page: 20,
          total: 1,
        },
        modal: {
          form: {
            show: false,
            id: null,
            premodel: {},
          },
        },
        list: [],
        portos: [],
        portosFiltro: [],
        search: {
          carga_tipo_id: [],
          porto_id: [],
          recebedores: [],
          embarcadores: [],
          agencia_id: [],
          navio_id: [],
          afretador_id: [],
          periodo_inicio: null,
          periodo_fim: null,
        },
        defaultSearch: {},
        orderBy: {
          campo: "name",
          ordem: "asc",
        },
        ops: [],
      };
    },
    created() {
      this.search.periodo_inicio = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
      this.search.periodo_fim = moment().endOf("month").format("YYYY-MM-DD");
      this.defaultSearch = { ...this.search };
      this.listarCargasTipos();
      this.getParams();

      PortosService.get({}).then((response) => {
        this.portosFiltro = response.data;
      });
    },
    watch: {
      cargas: {
        deep: true,
        handler() {
          this.setCargasByid();
        },
      },
      orderBy: {
        deep: true,
        handler() {
          this.pagination.page = 1;
          this.listar();
        },
      },
    },
    methods: {
      setCargasByid() {
        this.cargasByid = [];

        this.cargas.forEach(
          (value) => (this.cargasByid[value.id] = { ...value })
        );
      },
      getParams() {
        Service.parametros({
          navios: true,
          agencias: true,
          lineups_portos: true,
          afretadores: true,
          trabalhadores: true,
          ops: true,
          status: true,
        }).then((response) => {
          this.navios = response.data.navios;
          this.lineupPortos = response.data.lineups_portos;
          this.agencias = response.data.agencias;
          this.afretadores = response.data.afretadores;
          this.trabalhadores = response.data.trabalhadores;
          this.ops = response.data.ops;
          this.status = response.data.status;
        });
      },
      handleModalForm() {
        this.modal.form.show = false;
        this.modal.form.id = null;
        this.listar(true);
        this.getParams();
      },
      openCreateForm(lineup) {
        this.modal.form.show = true;
        this.modal.form.id = lineup.id;
        this.modal.form.premodel = lineup.premodel;
        this.modal.form.call_in_id = lineup.call_in_id;
      },
      handleExcluir(updated) {
        this.$root.modal.excluir.isOpen = false;
        if (updated) this.listar(true);
      },
      download(aviso) {
        if (aviso) {
          if (
            !confirm("Realmente deseja baixar com as agências?", "Sim", "Não")
          ) {
            return false;
          }
        }
        this.$notify({
          type: "info",
          message: "Please wait for the download to start",
          horizontalAlign: "center",
        });

        Service.download({
          ...this.search,

          agencia: aviso ? 1 : 0,
        }).then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          });
          const objectUrl = window.URL.createObjectURL(blob);

          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = objectUrl;

          a.download = "lineups.xlsx";
          a.click();
          window.URL.revokeObjectURL(objectUrl);
        });
      },
      downloadRecap() {
        this.$notify({
          type: "info",
          message: "Please wait for the download to start",
          horizontalAlign: "center",
        });

        Service.download({
          ...this.search,
          type: 2,
          agencia: 0,
        }).then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
          });
          const objectUrl = window.URL.createObjectURL(blob);

          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = objectUrl;

          a.download = "recap-lineups.xlsx";
          a.click();
          window.URL.revokeObjectURL(objectUrl);
        });
      },
      setCarga(carga) {
        this.cargaSelecionada = carga;
        this.search.carga_tipo_id = carga.id ? [carga.id] : [];

        this.listar();
      },
      cargaAtualizada(carga, atualizar) {
        this.loading = true;
        CargasTiposService.atualizar(carga, atualizar).then((response) => {
          carga.atualizado_data = response.data.atualizado_data;
          carga.atualizador = response.data.atualizador;
          this.$notify({
            type: "success",

            message: "Updated successfully!",
            horizontalAlign: "center",
          });
          this.loading = false;
        });
      },

      portoAtualizado(porto, carga, atualizar) {
        this.loading = true;
        PortosService.atualizar(porto, carga, atualizar).then((response) => {
          porto.carga_atualizada = response.data;
          this.$notify({
            type: "success",
            message: "Updated successfully!",
            horizontalAlign: "center",
          });
          this.loading = false;
        });
      },
      listarCargasTipos() {
        this.loading = true;
        this.cargas = [];
        CargasTiposService.get({
          with: ["cargas", "atualizador"],
          ...this.search,
        }).then((response) => {
          this.cargas = response.data;
          this.setCarga(this.cargas[0]);
          this.loading = false;
          // this.listar();
        });
      },
      toggleEditTerminal(terminal) {
        terminal.inEdit = terminal.inEdit ? false : true;
        if (!terminal.inEdit) {
          this.listar(true);
        }
      },
      listar(clear) {
        this.loading = true;
        if (!clear) {
          this.listarPortos();
          this.list = [];
        }

        Service.get({
          ...this.search,
          with: [
            "carga",
            "cargaTipo",
            "terminal",
            "berco",
            "recebedores",
            "afretador",
            "embarcadores",
            "agencia",
            "navio",
            "importPorto",
            "exportPorto",
            "ops",
            "lineupsCargas",
            "status",
          ],
        }).then((response) => {
          // this.list = response.data;
          let vue = this;
          this.portos.map((porto) => {
            porto.terminais.map((terminal) => {
              vue.$set(terminal, "inEdit", false);
              vue.$set(
                terminal,
                "lineups",
                response.data.filter((list) => list.terminal_id == terminal.id)
              );
            });
          });
          this.loading = false;
        });
      },
      cleanFiltros() {
        this.search = { ...this.defaultSearch };
        this.search.carga_tipo_id = this.cargaSelecionada.id
          ? [this.cargaSelecionada.id]
          : [];
        this.listar();
      },
      listarPortos() {
        this.loading = true;
        this.portos = [];
        PortosService.get({
          id: this.search.porto_id,
          campo: "ordem",
          ordem: "asc",
          carga_tipo_id: this.search.carga_tipo_id,
          with: "terminais.bercos",

          // ...this.search
        }).then((response) => {
          let vue = this;
          this.portos = response.data;
          this.portos.map((porto) => {
            porto.terminais.map((terminal) => {
              vue.$set(terminal, "inEdit", false);
            });
          });
          this.loading = false;
        });
      },
    },
  };
</script>
<style scoped="">
  .cursor-pointer {
    cursor: pointer;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
</style>
