<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Williams"
      title="Williams"
      v-if="$root.showDesktopSidebar"
    >
      <template slot="links">
        <sidebar-item
          v-if="$root.isAdmin"
          :link="{
            name: 'Cargo',
            icon: 'fa fa-truck text-green',
          }"
        >
          <sidebar-item
            :link="{ name: 'New', path: '/cargas_tipos/adicionar' }"
          />
          <sidebar-item
            :link="{
              name: 'List',
              path: '/cargas_tipos/listar',
              icon: 'fa fa-list',
            }"
          />
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Lineups',
            icon: 'fa fa-book-open text-dark',
          }"
        >
          <sidebar-item :link="{ name: 'New', path: '/lineups/adicionar' }" />
          <sidebar-item :link="{ name: 'Import', path: '/lineups/importar' }" />
          <sidebar-item
            :link="{
              name: 'List',
              path: '/lineups/listar',
              icon: 'fa fa-list',
            }"
          />
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Fields of lineup',
            icon: 'fa fa-book-open text-dark',
          }"
        >
          <sidebar-item
            :link="{ name: 'Charterers', path: '/campos/afretadores' }"
          />

          <sidebar-item
            :link="{ name: 'Agencies', path: '/campos/agencias' }"
          />

          <sidebar-item :link="{ name: 'Vessels', path: '/campos/navios' }" />

          <sidebar-item
            :link="{ name: 'Port Lineup', path: '/campos/lineup_porto' }"
          />

          <sidebar-item
            :link="{
              name: 'Shippers / Receivers',
              path: '/campos/trabalhadores',
            }"
          />
        </sidebar-item>
        <sidebar-item
          v-if="$root.isAdmin"
          :link="{
            name: 'Ports',
            icon: 'fa fa-ship text-blue',
          }"
        >
          <sidebar-item :link="{ name: 'New', path: '/portos/adicionar' }" />
          <sidebar-item
            :link="{
              name: 'List',
              path: '/portos/listar',
              icon: 'fa fa-list',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="$root.isAdmin"
          :link="{
            name: 'Users',
            icon: 'fa fa-users text-yellow',
          }"
        >
          <sidebar-item :link="{ name: 'New', path: '/usuarios/adicionar' }" />
          <sidebar-item
            :link="{
              name: 'List',
              path: '/usuarios/listar',
              icon: 'fa fa-list',
            }"
          />
          <sidebar-item
            :link="{ name: 'Activities', path: '/usuarios/atividades' }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="$root.isAdmin"
          :link="{
            name: 'Contacts',
            icon: 'fa fa-address-book ',
          }"
        >
          <sidebar-item :link="{ name: 'New', path: '/contatos/adicionar' }" />
          <sidebar-item
            :link="{
              name: 'List',
              path: '/contatos/listar',
              icon: 'fa fa-list',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="$root.isAdmin"
          :link="{
            name: 'WeeklyNews',
            icon: 'fa fa-paper-plane ',
          }"
        >
          <sidebar-item
            :link="{ name: 'New', path: '/weekly_news/adicionar' }"
          />
          <sidebar-item
            :link="{
              name: 'List',
              path: '/weekly_news/listar',
              icon: 'fa fa-list',
            }"
          />
        </sidebar-item>

        <sidebar-item
          v-if="$root.isAdmin"
          :link="{
            name: 'Distribution list',
            icon: 'fa fa-file-excel',
          }"
        >
          <sidebar-item
            :link="{ name: 'New', path: '/lista_distribuicao/adicionar' }"
          />
          <sidebar-item
            :link="{
              name: 'List',
              path: '/lista_distribuicao/listar',
              icon: 'fa fa-list',
            }"
          />
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
  import DashboardNavbar from "./DashboardNavbar.vue";
  import ContentFooter from "./ContentFooter.vue";
  import { FadeTransition } from "vue2-transitions";

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition,
    },
    data() {
      return {
        sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      };
    },

    methods: {
      toggleSidebar() {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false);
        }
      },
    },
  };
</script>
<style lang="scss"></style>
