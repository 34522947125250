<template>
  <div>
    <base-header class="pb-4 pb-4 pt-5 pt-md-5"> </base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Port</h3>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-1">
                  Information of port
                </h6>

                <div class="row">
                  <div class="col-lg-7">
                    <base-input
                      alternative=""
                      required
                      label="Name"
                      placeholder="port name"
                      input-classes="form-control-alternative"
                      :errors="errors.name"
                      v-model="model.name"
                    />
                  </div>
                  <div class="col-lg-3">
                    <base-input
                      alternative=""
                      required
                      label="Port code"
                      placeholder="port code"
                      input-classes="form-control-alternative"
                      :errors="errors.sigla"
                      v-model="model.sigla"
                    />
                  </div>
                  <div class="col-lg-2">
                    <base-input
                      alternative=""
                      type="number"
                      required
                      label="Order"
                      placeholder=""
                      input-classes="form-control-alternative"
                      :errors="errors.ordem"
                      v-model="model.ordem"
                    />
                  </div>
                </div>

                <h6 class="heading-small text-muted mb-2">
                  Terminals of this port
                  <base-button
                    type="primary"
                    class="float-right"
                    icon
                    size="sm"
                    @click="addTerminal()"
                  >
                    <i class="fas fa-plus mr-2"></i>
                    <span class="btn-inner--text">Add terminal</span>
                  </base-button>
                  <div
                    class="text-danger invalid-feedback"
                    style="display: block"
                    v-for="erro in errors['terminais']"
                    v-if="errors['terminais']"
                  >
                    {{ erro }}
                  </div>
                </h6>

                <div class="row">
                  <div class="col-md-12">
                    <div
                      class="card my-3"
                      v-for="(terminal, terminalIndex) in model.terminais"
                    >
                      <div class="card-header pb-0" style="border-bottom: none">
                        <div class="form-row">
                          <div class="col-lg-9">
                            <base-input
                              alternative=""
                              required
                              placeholder="terminal name"
                              input-classes="form-control-alternative"
                              :errors="
                                errors['terminais.' + terminalIndex + '.name']
                              "
                              v-model="terminal.name"
                            />
                          </div>
                          <div class="col-lg-2">
                            <base-input
                              type="number"
                              placeholder="Order"
                              input-classes="form-control-alternative"
                              :errors="
                                errors['terminais.' + terminalIndex + '.ordem']
                              "
                              v-model="terminal.ordem"
                            />
                          </div>
                          <div class="col-lg-1">
                            <div class="form-group has-label">
                              <button
                                type="submit"
                                class="btn base-button btn-danger w-100"
                                :disabled="loading"
                                @click="removeTerminal(terminalIndex)"
                              >
                                <i class="fa fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body pt-0">
                        <div class="card pt-0">
                          <div class="card-header pb-0">
                            <h6 class="heading-small text-muted mb-4">
                              Cargos from this terminal
                            </h6>
                          </div>
                          <div class="card-body pb-0">
                            <div class="form-row">
                              <div class="col-lg-12">
                                <multiple-select
                                  multiple
                                  :close-on-select="false"
                                  :clear-on-select="false"
                                  placeholder="Select the cargos"
                                  :options="cargasTipos"
                                  optionsValue="name"
                                  v-model="terminal.cargas_ids"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="card pt-0">
                          <div class="card-header pb-0">
                            <h6 class="heading-small text-muted mb-4">
                              Berths of this terminal
                              <base-button
                                type="primary"
                                class="float-right"
                                icon
                                size="sm"
                                @click="addBerco(terminal)"
                              >
                                <i class="fas fa-plus mr-2"></i>
                                <span class="btn-inner--text">Add berth</span>
                              </base-button>
                            </h6>
                          </div>
                          <div class="card-body pb-0">
                            <div
                              class="form-row"
                              v-for="(berco, bercoIndex) in terminal.bercos"
                            >
                              <div class="col-lg-11">
                                <base-input
                                  alternative=""
                                  required
                                  placeholder="berth name"
                                  input-classes="form-control-alternative"
                                  :errors="
                                    errors[
                                      'terminais.' +
                                        terminalIndex +
                                        '.bercos.' +
                                        bercoIndex +
                                        '.name'
                                    ]
                                  "
                                  v-model="berco.name"
                                />
                              </div>
                              <div class="col-lg-1">
                                <div class="form-group has-label">
                                  <button
                                    type="submit"
                                    class="btn base-button btn-danger w-100"
                                    :disabled="loading"
                                    @click="removeBerco(terminal, bercoIndex)"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>

            <div class="mt-4">
              <button
                type="submit"
                class="btn base-button btn-dark float-left"
                @click="$router.push('/portos/listar')"
              >
                See listing
              </button>
              <button
                type="submit"
                class="btn base-button btn-primary float-right"
                :disabled="loading"
                @click="submit()"
              >
                Save port
              </button>
            </div>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Service from "@/services/Portos";
  import CargasTiposService from "@/services/CargasTipos";
  export default {
    name: "usuario_form",
    created() {
      if (this.$route.params.id) {
        this.model.id = this.$route.params.id;
        this.find();
      }
      CargasTiposService.get({}).then((response) => {
        this.cargasTipos = response.data;
      });
    },
    data() {
      return {
        errors: {},
        loading: false,
        Service,
        cargasTipos: [],
        model: {
          nome: "",
          sigla: "",
          terminais: [],
          id: null,
        },
      };
    },
    methods: {
      addTerminal() {
        this.model.terminais.unshift({
          id: null,
          name: "",
          ordem: 0,
          porto_id: this.model.id,
          bercos: [],
          cargas_ids: [],
        });
      },
      removeTerminal(index) {
        this.model.terminais.splice(index, 1);
        this.$notify({
          type: "info",
          message:
            "Terminal removed. This change will only take effect after saving the port.",
          horizontalAlign: "center",
        });
      },
      removeBerco(terminal, index) {
        terminal.bercos.splice(index, 1);
        this.$notify({
          type: "info",
          message:
            "Berth removed. This change will only take effect after saving the port",
          horizontalAlign: "center",
        });
      },
      addBerco(terminal) {
        terminal.bercos.push({
          id: null,
          name: "",
          terminal_id: terminal.id,
          bercos: [],
        });
      },
      find() {
        this.loading = true;
        this.errors = {};
        Service.find(this.model.id, {
          with: "terminais.bercos",
          terminais_cargas: true,
        })
          .then((response) => {
            this.model = response.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      submit() {
        this.loading = true;
        this.errors = {};
        Service.submit({ ...this.model, terminais_cargas: true })
          .then((response) => {
            this.model = response.data;
            this.loading = false;
            this.$notify({
              type: "success",
              message: "Port created successfullt!",
              horizontalAlign: "center",
            });
          })
          .catch((err) => {
            this.loading = false;
            if (err.response && err.response.data && err.response.data.errors) {
              let errors = err.response.data.errors;
              this.errors = errors;
              this.$notify({
                type: "danger",
                message: "Please check the form",
                horizontalAlign: "center",
              });
            }
          });
      },
    },
  };
</script>
<style></style>
