<template>
  <div>
    <base-header class="pb-4 pb-4 pt-5 pt-md-5"> </base-header>

    <div class="container-fluid mt-5">
      <div class="row">
        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <div slot="header" class="bg-white border-0">
              <div class="row align-items-center">
                <div class="col-8">
                  <h3 class="mb-0">Contact</h3>
                </div>
                <div class="col-4 text-right">
                  <!-- <a href="#!" class="btn btn-sm btn-primary">Settings</a> -->
                </div>
              </div>
            </div>
            <template>
              <form @submit.prevent>
                <h6 class="heading-small text-muted mb-4">
                  Information of contact
                </h6>

                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      required
                      label="Name"
                      placeholder="contact name"
                      input-classes="form-control-alternative"
                      :errors="errors.name"
                      v-model="model.name"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                      alternative=""
                      required
                      type="email"
                      label="Email"
                      placeholder="name@williams.com"
                      input-classes="form-control-alternative"
                      :errors="errors.email"
                      v-model="model.email"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-select
                      alternative=""
                      label="Company"
                      :errors="errors.empresa_id"
                      placeholder="select the agency"
                      :options="empresas"
                      addonLeftIcon=" fa fa-plus"
                      @btn="openModalCreate()"
                      input-classes="form-control-alternative"
                      v-model="model.empresa_id"
                    />
                  </div>
                </div>
                <button
                  class="btn btn-sm btn-primary mb-2"
                  @click="setCargas(true)"
                >
                  Add all
                </button>
                <button
                  class="btn btn-sm btn-danger mb-2"
                  @click="setCargas(false)"
                >
                  Remove all
                </button>
                <div class="row mb-3">
                  <div
                    class="col-lg-2 col-md-3"
                    v-for="(carga, index) in cargas"
                  >
                    <div class="card">
                      <div class="card-body">
                        <div class="form-check form-check-inline">
                          <input
                            type="checkbox"
                            class="form-check-input"
                            :id="index"
                            :value="carga.id"
                            v-model="model.cargas"
                          />
                          <label class="form-check-label" :for="index">{{
                            carga.name
                          }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>

            <button
              type="submit"
              class="btn base-button btn-dark float-left"
              @click="$router.push('/contacts/listar')"
            >
              See listing
            </button>
            <button
              type="submit"
              class="btn base-button btn-primary float-right"
              :disabled="loading"
              @click="submit()"
            >
              Save contact
            </button>
          </card>
        </div>
      </div>
    </div>
    <modal
      :show="modal.create.show"
      @close="
        () => {
          modal.create.show = false;
        }
      "
    >
      <template v-slot:header> Add new </template>
      <template>
        <generic-form
          v-if="modal.create.show"
          :service="modal.create.service"
          :type="modal.create.type"
          :params="modal.create.params"
          @success="handleModalForm"
        />
      </template>
    </modal>
  </div>
</template>
<script>
  import Service from "@/services/Contatos";
  import EmpresasService from "@/services/Empresas";
  import CargasTiposService from "@/services/CargasTipos";
  import GenericForm from "./../Generic/Form.vue";
  export default {
    name: "usuario_form",
    created() {
      EmpresasService.get({}).then((response) => {
        this.empresas = response.data;
      });
      CargasTiposService.get({}).then((response) => {
        this.cargas = response.data;
      });
      if (this.$route.params.id) {
        this.model.id = this.$route.params.id;
        this.find();
      }
    },
    components: {
      "generic-form": GenericForm,
    },
    data() {
      return {
        errors: {},
        empresas: [],
        cargas: [],
        loading: false,
        portos: [],
        Service,
        modal: {
          create: {
            show: false,
            service: {},
            type: null,
            params: {},
            model: null,
            index: null,
          },
        },
        model: {
          nome: "",
          email: "",
          empresa_id: null,
          cargas: [],
          id: null,
        },
      };
    },
    methods: {
      find() {
        this.loading = true;
        this.errors = {};
        Service.find(this.model.id, { with: "cargas_tipos" })
          .then((response) => {
            this.model = response.data;
            this.model.cargas = [];
            this.fixCargas();
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      },
      fixCargas() {
        let cargas = this.model.cargas_tipos.map((carga) => carga.id);
        this.$set(this.model, "cargas", cargas);
      },
      setCargas(adicionar) {
        if (adicionar) {
          this.model.cargas = this.cargas.map((carga) => carga.id);
        } else {
          this.model.cargas = [];
        }
        this.$forceUpdate();
      },
      openModalCreate() {
        this.modal.create.service = EmpresasService;
        this.modal.create.params = {};

        this.modal.create.type = "empresa";
        this.modal.create.show = true;
      },
      handleModalForm(model) {
        this.model.empresa_id = model.id;
        this.empresas.push(model);

        this.modal.create.type = null;
        this.modal.create.show = false;

        // this.$forceUpdate();
      },
      submit() {
        this.loading = true;
        this.errors = {};
        Service.submit(this.model)
          .then((response) => {
            this.model = response.data;
            this.fixCargas();
            this.loading = false;
            this.$notify({
              type: "success",
              message: "contact salvo com sucesso!",
              horizontalAlign: "center",
            });
          })
          .catch((err) => {
            this.loading = false;
            if (err.response && err.response.data && err.response.data.errors) {
              let errors = err.response.data.errors;
              this.errors = errors;
              this.$notify({
                type: "danger",
                message: "Please check the form",
                horizontalAlign: "center",
              });
            }
          });
      },
    },
  };
</script>
<style></style>
